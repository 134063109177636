<template>
    <div>
        <slot></slot>
        <!-- <div
        style="
                position: fixed;
                top: 30px;
                left: 30px;
                font-size: 73px;
                background-color: #ffffffaa;
                color: #3e85b3;
                padding: 5px 150px;
                border-radius: 10px;
                transform: rotate(-45deg) translate(-245px, -35px);
                z-index:99999
        "
    >
        Hàng trưng bày
    </div> -->
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>