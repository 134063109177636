<template>
    <DxGallery
        :data-source="DataSource"
        :height="Height"
        :show-nav-buttons="ShowNavButton"
        :loop="Loop"
        :stretchImages="true"
        :slideshow-delay="delay || SlideshowDelay"
        :show-indicator="ShowIndicator"
        @option-changed="handlePropertyChange"
        :width="width"
    >
        <template #item="{ data }">
            <div class="content">
                <img
                    :src="data.hinhNenQuangCao || data.hinhAnhUrl"
                    loading="lazy"
                    width="100%"
                    height="auto"
                />
            </div>
        </template>
    </DxGallery>
</template>
<script>
import { DxGallery } from "devextreme-vue";
export default {
    components: {
        DxGallery,
    },
    props: {
        ShowNavButton: {
            type: Boolean,
            default: false,
        },
        Loop: {
            type: Boolean,
            default: true,
        },
        ShowIndicator: {
            type: Boolean,
            default: true,
        },
        Height: {
            type: String,
            default: "100%",
        },
        width: {
            type: String,
            default: "100%",
            required: false,
        },
        SlideshowDelay: {
            type: Number,
            default: 3000,
        },
        DataSource: {
            type: Array,
            default: () => {
                [];
            },
        },
    },
    data() {
        return {
            data: [],
            delay: 3000,
        };
    },
    methods: {
        handlePropertyChange: function (e) {
            if (Array.isArray(e.value) && e.value.length > 0) {
                if (e.value[0].thoiGianHienThi > 0) {
                    this.delay = e.value[0].thoiGianHienThi;
                }
            }
        },
    },
};
</script>

<style scoped>
>>> .content img {
    width: 100%;
    height: 100%;
}
</style>