<template>
    <div class="row wrap justify-space-between">
        <div v-for="(item, index) in data" :key="index">
            <div
                class="img row justify-center align-center"
                @click="
                    setGuidNhaXe(item.guidNhaXe),
                        $router.push('/tim-kiem-chuyen-di')
                "
            >
                <div
                    style="
                        width: 120px;
                        height: 120px;
                        border: 1px solid #ddd;
                        border-radius: 50%;
                    "
                    :style="`background-image: url(${item.logoUrl});background-repeat: round;`"
                ></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
    components: {},
    props: {},
    data() {
        return {
            data: [],
        };
    },
    methods: {
        ...mapActions("trangChu", ["layDsNhaXeSuDungQuangCao"]),
        ...mapMutations("timKiemChuyenDi", ["setGuidNhaXe"]),
    },
    created() {},
    async mounted() {
        let thongTinNhaXeSuDungQuangCao = await this.layDsNhaXeSuDungQuangCao();
        this.data = thongTinNhaXeSuDungQuangCao || [];
    },
};
</script>
<style lang='scss' scoped>
.img {
    padding: 4px;
    border: 1px solid $primary;
    width: 130px;
    height: 130px;
    border-radius: 50%;
}
</style>